import { AppointmentTypeIds } from "./Fhir";
import { LanguageIsoCodes } from "./languageData";
 
 
const PUBLIC_URL = process?.env?.PUBLIC_URL || "";
export const TermsOfUseUrl: string = `${PUBLIC_URL}/docs/terms_of_use_v1.pdf`;
export const PrivacyUrl: string = `${PUBLIC_URL}/docs/privacy_v1.pdf`;
export const TermsOfUseVersion: string = "1";
export const PrivacyVersion: string = "1";
 
export enum PciDocTypes {
  NONE                  = "",                       // Invalid doc type
  REGISTRATION          = "REGISTRATION",           // Patient registration II
  COVERED_SERVICES      = "COVERED_SERVICES",       // Covered Services Notice – Annual Exam
  OPEN_PAYMENTS         = "OPEN_PAYMENTS",          // Open Payments Notice to Patients
  HIPAA                 = "HIPAA",                  // HIPAA Summary of Notice of Privacy Practices and Acknowledgment form
  ADVANCE_DIRECTIVES    = "ADVANCE_DIRECTIVES",     // Advanced Directive Form
}
 
// all forms are stored under this appointmentTypeId
export const DocAppointmentTypeId: string = AppointmentTypeIds.NewPatient;
 
// NOTE: all 3 form types are now included for all appointment types and the default
// map of expected forms based on PCI appointmentTypeId
const AppointmentTypeIdToPciDocTypesMap = {
  [AppointmentTypeIds.NewPatient]:          [ PciDocTypes.REGISTRATION, PciDocTypes.HIPAA, PciDocTypes.COVERED_SERVICES, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [AppointmentTypeIds.EstablishedPatient]:  [ PciDocTypes.REGISTRATION, PciDocTypes.HIPAA, PciDocTypes.COVERED_SERVICES, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
  [AppointmentTypeIds.PhysicalExam]:        [ PciDocTypes.REGISTRATION, PciDocTypes.HIPAA, PciDocTypes.COVERED_SERVICES, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ],
};
const DefaultDocTypes =                     [ PciDocTypes.REGISTRATION, PciDocTypes.HIPAA, PciDocTypes.COVERED_SERVICES, PciDocTypes.OPEN_PAYMENTS, PciDocTypes.ADVANCE_DIRECTIVES ];
 
// map of form template key based on PciDocType  
// NOTE: this are Cali CDO specific
const PciDocTypeToFormKeyMap = {
  [LanguageIsoCodes.eng]: {
    [PciDocTypes.REGISTRATION]: "caliRegForm_eng",
    [PciDocTypes.COVERED_SERVICES]: "caliAnnualExamForm_eng",
    [PciDocTypes.OPEN_PAYMENTS]: "caliOpenPaymentsForm_eng",
    [PciDocTypes.ADVANCE_DIRECTIVES]: "caliAdvanceDirectives_check000_eng",    // special case based on checkbox selected
    [PciDocTypes.HIPAA]: "caliHipaaForm_eng",
 },
  [LanguageIsoCodes.spa]: {
    [PciDocTypes.REGISTRATION]: "caliRegForm_spa",
    [PciDocTypes.COVERED_SERVICES]: "caliAnnualExamForm_spa",
    [PciDocTypes.OPEN_PAYMENTS]: "caliOpenPaymentsForm_spa",
    [PciDocTypes.ADVANCE_DIRECTIVES]: "caliAdvanceDirectives_check000_spa",    // special case based on checkbox selected
    [PciDocTypes.HIPAA]: "caliHipaaForm_spa",
  },
};
 
const AdvancedDirectiveToSubKeyMap = {
  "default": "check000",
  "option1": "check100",
  "option2": "check010",
  "option3": "check001",
};
 
const makePciDocTypeLookupMap = (): any => {
  let map: any = {};
  Object.values<any>(PciDocTypeToFormKeyMap).forEach((langMap: any) => {
    Object.entries<any>(langMap).forEach(([pciDocType, formKey]) => {
      if (pciDocType && formKey) {
        if (pciDocType === PciDocTypes.ADVANCE_DIRECTIVES) {
          Object.entries(AdvancedDirectiveToSubKeyMap).forEach(([advDirOptionKey, subKey]) => {
            const tmpFormKey = formKey.replace("_check000_", "_" + subKey + "_");
            map[tmpFormKey] = pciDocType;
          });
        } else {
          map[formKey] = pciDocType;
        }
      }
    });
  });
  return map;
};
 
const templateKeyToDocTypeMap: any = makePciDocTypeLookupMap();
 
export const GetFormTemplateKeyFromPciDocType = (
  docType: PciDocTypes,
  languageIsoCode: string,
  advancedDirectiveSelection: string = ""
): string => {
  let tmpMap: any = PciDocTypeToFormKeyMap[languageIsoCode] || {};
  let formKey: string = tmpMap[docType] || "";
  if (docType === PciDocTypes.ADVANCE_DIRECTIVES && formKey) {
    const subKey = AdvancedDirectiveToSubKeyMap[advancedDirectiveSelection] || AdvancedDirectiveToSubKeyMap.default;
    formKey = formKey.replace("_check000_", "_" + subKey + "_");
  }
  return formKey;
};
 
export const GetDocTypeFromTemplateKey = (templateKey: string): PciDocTypes => {
  let docType: PciDocTypes = PciDocTypes.NONE;
  let tmp: any = templateKey? templateKeyToDocTypeMap[templateKey]: undefined;
  if (tmp !== undefined) {
    docType = tmp as PciDocTypes;
  }
  return docType;
};
 
export interface DocCounts {
  [PciDocTypes.REGISTRATION]: number;
  [PciDocTypes.COVERED_SERVICES]: number;
  [PciDocTypes.OPEN_PAYMENTS]: number;
  [PciDocTypes.ADVANCE_DIRECTIVES]: number;
  [PciDocTypes.HIPAA]: number;
}
 
const emptyDocCounts: DocCounts = {
  [PciDocTypes.REGISTRATION]: 0,
  [PciDocTypes.COVERED_SERVICES]: 0,
  [PciDocTypes.OPEN_PAYMENTS]: 0,
  [PciDocTypes.ADVANCE_DIRECTIVES]: 0,
  [PciDocTypes.HIPAA]: 0,
};
 
export const CreateNewDocCounts = (initDocCounts: any = undefined): DocCounts => {
  return {
    ...emptyDocCounts,
    ...initDocCounts,
  } as DocCounts;  
};
 
export const HasAllExpectedFormsForAppointmentTypeId = (docCounts: DocCounts | undefined, apptTypeId: string, filterDocTypes: PciDocTypes[] | undefined = undefined): boolean => {
  let docTypes: PciDocTypes[] = AppointmentTypeIdToPciDocTypesMap[apptTypeId] || DefaultDocTypes;
 
  if (filterDocTypes !== undefined) {
    docTypes = docTypes?.filter(v => filterDocTypes.includes(v));
  }
 
  return HasAllExpectedFormsForAllPciDocTypes(docCounts, docTypes);
};
 
export const HasAllExpectedFormsForAllPciDocTypes = (docCounts: DocCounts | undefined, docTypes: PciDocTypes[]): boolean => {
  let retval: boolean = true;
  if (docTypes?.length > 0) {
    // this is a negative search, if we find a match, then we don't have a valid doc count and we can bail
    docTypes.find(docType => {
      let count = docCounts? docCounts[docType]: 0;
      if (count === undefined || count <= 0) {
        retval = false;
        return true;
      }
      return false;
    });
  }
  return retval;
};
 
export const GetFormTemplateKeysFromAppointmentTypeId = (
  apptTypeId: string,
  languageIsoCode: string,
  advancedDirectiveEnabled: boolean = true,
  advancedDirectiveSelection: string = ""
): string[] => {
  return GetFormTemplateKeysFromDocCounts(null, apptTypeId, languageIsoCode, advancedDirectiveEnabled, advancedDirectiveSelection);
};
 
export const GetFormTemplateKeysFromDocCounts = (
  docCounts: DocCounts | undefined | null,
  apptTypeId: string,
  languageIsoCode: string,
  advancedDirectiveEnabled: boolean = true,
  advancedDirectiveSelection: string = ""
): string[] => {
  // NOTE: only use DefaultDocTypes if undefined.  if we get empty list [] back, then assume we want an empty list.
  let docTypes: PciDocTypes[] = AppointmentTypeIdToPciDocTypesMap[apptTypeId] || DefaultDocTypes;
 
  if (!advancedDirectiveEnabled) {
    docTypes = docTypes.filter((docType: PciDocTypes) => (docType !== PciDocTypes.ADVANCE_DIRECTIVES));
  }
 
  let templateKeys: string[] = [];
  if (docTypes?.length > 0) {
    docTypes.forEach(docType => {
      let count = docCounts? docCounts[docType]: 0;
      if (count === undefined || count <= 0) {
        let formKey: string = GetFormTemplateKeyFromPciDocType(docType, languageIsoCode, advancedDirectiveSelection);
        if (formKey) {
          templateKeys.push(formKey)
        }
      }
    });
  }
  return templateKeys;
};
 